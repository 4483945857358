var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "a-card",
        {
          attrs: {
            title: _vm.$t("lbl_ar_aging_reminder"),
            loading: _vm.loading
          }
        },
        [
          _c(
            "a-row",
            {
              attrs: { gutter: [16, 16], type: "flex", justify: "space-around" }
            },
            _vm._l(_vm.dataReport, function(value) {
              return _c(
                "a-col",
                { key: value.key, attrs: { flex: "1" } },
                [
                  _c(
                    "a-card",
                    {
                      attrs: { title: _vm.getTitle("outstanding" + value.key) }
                    },
                    [
                      _c(
                        "a-descriptions",
                        { attrs: { column: 1 } },
                        [
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: _vm.$t("lbl_total_amount") } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("currency")(value.totalAmount)
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: _vm.$t("lbl_total_invoice") } },
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: { size: "small", type: "link" },
                                  on: {
                                    click: function($event) {
                                      return _vm.toggleDrawer(value)
                                    }
                                  }
                                },
                                [_vm._v(" " + _vm._s(value.totalInvoice) + " ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      ),
      _c("DrawerInvoice", {
        attrs: { visible: _vm.drawer.visible, params: _vm.drawer.data },
        on: { close: _vm.onDrawerClose }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }