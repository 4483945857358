













































import currencyFilter from "@/filters/currency.filter";
import {
  setNumbering,
  toTitlecase,
  trimSpaceToUnderscore,
} from "@/helpers/common";
import { APagination, useArAgingReport } from "@/hooks";
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  PAGE_SIZE_OPTIONS,
} from "@/models/constant/global.constant";
import { Pagination } from "@/models/constant/interface/common.interface";
import {
  ReportAgingDashboardDetailDto,
  ReportAgingDashboardDetailParams,
  ReportAgingDashboardDto,
} from "@/models/interface/ar-aging-report/v2";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

type Record = { no: number } & ReportAgingDashboardDetailDto;

@Component
export default class DrawerInvoice extends Vue {
  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;

  @Prop({ required: false, type: Boolean, default: false })
  visible!: boolean;

  @Prop({ required: false, type: Object })
  params!: ReportAgingDashboardDto;

  loading = false;
  dataSource: Record[] = [];

  columns = [
    {
      title: this.$t("lbl_number_short"),
      dataIndex: "no",
      width: "75px",
    },
    {
      title: this.$t("lbl_customer_name"),
      dataIndex: "customerName",
    },
    {
      title: this.$t("lbl_invoice_number"),
      dataIndex: "invoiceNumber",
      scopedSlots: { customRender: "invoice" },
    },
    {
      title: this.$t("lbl_invoice_source"),
      dataIndex: "source",
      customRender: text => toTitlecase(text),
    },
    {
      title: this.$t("lbl_outstanding"),
      dataIndex: "amount",
      customRender: text => currencyFilter(text),
    },
  ];

  pagination = {
    page: DEFAULT_PAGE,
    limit: DEFAULT_PAGE_SIZE,
    totalElements: 0,
    totalPages: 0,
    reset(): void {
      this.page = DEFAULT_PAGE;
      this.limit = DEFAULT_PAGE_SIZE;
      this.totalElements = 0;
      this.totalPages = 0;
    },
  };

  @Watch("visible")
  onVisible(newVal: boolean): void {
    if (newVal && this.params.key) {
      const params = this.buildDetailParams(this.params.key);
      this.getDetailInvoice(params);
    }
  }

  getDetailInvoice(params?: ReportAgingDashboardDetailParams): void {
    this.loading = true;
    this.fetchDetail(params)
      .then(response => {
        this.pagination.totalElements = response.totalElements;
        this.pagination.totalPages = response.totalPages;
        this.dataSource = response.data.map((item, index) => ({
          ...item,
          no: setNumbering(
            this.pagination.page - 1,
            this.pagination.limit,
            index
          ),
        }));
      })
      .finally(() => {
        this.loading = false;
      });
  }

  emits(): void {
    this.dataSource = [];
    this.pagination.reset();
    this.$emit("close");
  }

  getRoute(source: string): string {
    const RECURRING_INVOICE = "RECURRING_INVOICE";
    if (trimSpaceToUnderscore(source) === RECURRING_INVOICE) {
      return "accountreceivables.invoices.detail.recurring";
    } else {
      return "accountreceivables.invoices.detail";
    }
  }

  onTableChange(pagination: APagination): void {
    const { current, pageSize } = pagination;

    this.pagination.page = current;

    if (this.pagination.limit !== pageSize) {
      this.pagination.page = DEFAULT_PAGE;
    }

    this.pagination.limit = pageSize;

    const params = this.buildDetailParams(this.params.key);
    this.getDetailInvoice(params);
  }

  async fetchDetail(
    params?: ReportAgingDashboardDetailParams
  ): Promise<Pagination<ReportAgingDashboardDetailDto>> {
    const { findDetail } = useArAgingReport();
    const response = await findDetail(params);
    return response;
  }

  buildDetailParams(key: number): ReportAgingDashboardDetailParams {
    const params: ReportAgingDashboardDetailParams = {
      page: this.pagination.page - 1,
      limit: this.pagination.limit,
    };

    switch (key) {
      case 1:
        params.greaterThan = 0;
        params.lessThan = 15;
        break;
      case 2:
        params.greaterThan = 15;
        params.lessThan = 30;
        break;
      case 3:
        params.greaterThan = 30;
        params.lessThan = 60;
        break;
      default:
        params.greaterThan = 60;
        break;
    }

    return params;
  }
}
