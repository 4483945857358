var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "a-card",
        {
          attrs: {
            title: _vm.$t("common.detail-text", {
              text: _vm.$t("lbl_asset_transfer")
            })
          }
        },
        [
          _c(
            "a-form-model",
            {
              attrs: {
                "label-col": { span: 10 },
                "wrapper-col": { span: 14 },
                layout: "vertical",
                "label-align": "left",
                "data-testid": "asset-transfer-detail-form"
              }
            },
            [
              _c(
                "a-row",
                { attrs: { gutter: [16, 16] } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_document_number"),
                            prop: "documentNumber"
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.assetTransferResponseDTO.documentNo,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.assetTransferResponseDTO,
                                  "documentNo",
                                  $$v
                                )
                              },
                              expression: "assetTransferResponseDTO.documentNo"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: _vm.$t("lbl_from"), prop: "from" } },
                        [
                          _c("a-textarea", {
                            attrs: { disabled: "", "auto-size": "" },
                            model: {
                              value: _vm.assetTransferResponseDTO.from,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.assetTransferResponseDTO,
                                  "from",
                                  $$v
                                )
                              },
                              expression: "assetTransferResponseDTO.from"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: _vm.$t("lbl_to"), prop: "to" } },
                        [
                          _c("a-textarea", {
                            attrs: { disabled: "", "auto-size": "" },
                            model: {
                              value: _vm.assetTransferResponseDTO.to,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.assetTransferResponseDTO,
                                  "to",
                                  $$v
                                )
                              },
                              expression: "assetTransferResponseDTO.to"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: _vm.$t("lbl_date"), prop: "to" } },
                        [
                          _c("a-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.assetTransferResponseDTO.date,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.assetTransferResponseDTO,
                                  "date",
                                  $$v
                                )
                              },
                              expression: "assetTransferResponseDTO.date"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_expedition"),
                            prop: "expedition"
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: { disabled: "" },
                            model: {
                              value:
                                _vm.assetTransferResponseDTO.expeditionName,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.assetTransferResponseDTO,
                                  "expeditionName",
                                  $$v
                                )
                              },
                              expression:
                                "assetTransferResponseDTO.expeditionName"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_expedition_address"),
                            prop: "expeditionAddress"
                          }
                        },
                        [
                          _c("a-textarea", {
                            attrs: { disabled: "", "auto-size": "" },
                            model: {
                              value:
                                _vm.assetTransferResponseDTO.expeditionAddress,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.assetTransferResponseDTO,
                                  "expeditionAddress",
                                  $$v
                                )
                              },
                              expression:
                                "assetTransferResponseDTO.expeditionAddress"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_attachment"),
                            prop: "documentNumber"
                          }
                        },
                        [
                          !!_vm.assetTransferResponseDTO.url
                            ? _c(
                                "a-button",
                                {
                                  attrs: { type: "link" },
                                  on: {
                                    click: function($event) {
                                      return _vm.downloadAttachment(
                                        _vm.assetTransferResponseDTO.url,
                                        _vm.assetTransferResponseDTO.documentNo
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.assetTransferResponseDTO.documentNo
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            : _c("p", [_vm._v("-")])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_truck_number"),
                            prop: "truckNumber"
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.assetTransferResponseDTO.truckNumber,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.assetTransferResponseDTO,
                                  "truckNumber",
                                  $$v
                                )
                              },
                              expression: "assetTransferResponseDTO.truckNumber"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_driver_name_and_phone_number"),
                            prop: "driverNameAndPhoneNumber"
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.assetTransferResponseDTO.driverName,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.assetTransferResponseDTO,
                                  "driverName",
                                  $$v
                                )
                              },
                              expression: "assetTransferResponseDTO.driverName"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: { label: _vm.$t("lbl_notes"), prop: "notes" }
                        },
                        [
                          _c("a-textarea", {
                            attrs: { disabled: "", "auto-size": "" },
                            model: {
                              value: _vm.assetTransferResponseDTO.note,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.assetTransferResponseDTO,
                                  "note",
                                  $$v
                                )
                              },
                              expression: "assetTransferResponseDTO.note"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: { label: _vm.$t("lbl_status"), prop: "status" }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.assetTransferResponseDTO.status) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c("a-table", {
                        attrs: {
                          size: "small",
                          "row-key": "id",
                          columns: _vm.columns,
                          "data-source": _vm.units,
                          scroll: { x: 1000 },
                          "data-testid": "asset-transfer-detail-table"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24, align: "end" } },
                    [
                      _c(
                        "a-space",
                        [
                          _c(
                            "a-button",
                            {
                              attrs: {
                                loading:
                                  _vm.loading.cancel ||
                                  _vm.loading.fetchDetail ||
                                  _vm.loading.print ||
                                  _vm.loading.receive,
                                "data-testid": "asset-transfer-detail-btn-back"
                              },
                              on: { click: _vm.handleBack }
                            },
                            [_vm._v(_vm._s(_vm.$t("lbl_back")))]
                          ),
                          _vm.$can("cancel", "asset-transfer") &&
                          _vm.isSubmitted
                            ? _c(
                                "a-popconfirm",
                                {
                                  attrs: {
                                    title: _vm.$t("pop_confirmation"),
                                    "ok-text": _vm.$t("lbl_yes"),
                                    "cancel-text": _vm.$t("lbl_no"),
                                    "data-testid":
                                      "asset-transfer-detail-btn-cancel"
                                  },
                                  on: { confirm: _vm.handleCancel }
                                },
                                [
                                  _c(
                                    "a-button",
                                    {
                                      attrs: {
                                        type: "danger",
                                        loading:
                                          _vm.loading.cancel ||
                                          _vm.loading.fetchDetail ||
                                          _vm.loading.print ||
                                          _vm.loading.receive
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("lbl_cancel")))]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.$can("read", "asset-transfer")
                            ? _c(
                                "a-button",
                                {
                                  attrs: {
                                    loading:
                                      _vm.loading.cancel ||
                                      _vm.loading.fetchDetail ||
                                      _vm.loading.print ||
                                      _vm.loading.receive,
                                    "data-testid":
                                      "asset-transfer-detail-btn-print",
                                    type: "primary"
                                  },
                                  on: { click: _vm.handlePrint }
                                },
                                [_vm._v(_vm._s(_vm.$t("lbl_print")))]
                              )
                            : _vm._e(),
                          _vm.$can("approve", "asset-transfer") &&
                          _vm.isSubmitted
                            ? _c(
                                "a-button",
                                {
                                  attrs: {
                                    loading:
                                      _vm.loading.cancel ||
                                      _vm.loading.fetchDetail ||
                                      _vm.loading.print ||
                                      _vm.loading.receive,
                                    "data-testid":
                                      "asset-transfer-detail-btn-receive",
                                    type: "primary"
                                  },
                                  on: { click: _vm.handleReceive }
                                },
                                [_vm._v(_vm._s(_vm.$t("lbl_receive")))]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("ReceiveAssetTransferModal", {
        attrs: {
          assetTransferResponseDTO: _vm.assetTransferResponseDTO,
          visible: _vm.modal.visible
        },
        on: {
          close: function($event) {
            return _vm.modal.close()
          },
          success: _vm.handleSuccess
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }