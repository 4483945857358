












































































import { Messages } from "@/models/enums/messages.enum";
import { ListCompanyDto } from "@/models/interface/company";
import { authService } from "@/services/auth.service";
import { companyService } from "@/services/company.service";
import { Form } from "ant-design-vue";
import { Component, Vue } from "vue-property-decorator";

/**
 * SigninForm Component
 *
 * A reusable component for handling user authentication.
 * Uses ant-design-vue Form component with TypeScript support.
 * Implements debounced company search and form validation.
 */
@Component({
  name: "SigninForm",
})
export default class SigninForm extends Vue {
  private formState = {
    company: undefined,
    username: "",
    password: "",
  };

  private companies: ListCompanyDto[] = [];
  private isSubmitting = false;

  private rules = {
    company: [
      {
        required: true,
        message: this.$t(Messages.VALIDATION_REQUIRED_ERROR).toString(),
        trigger: "change",
      },
    ],
    username: [
      {
        required: true,
        message: this.$t(Messages.VALIDATION_REQUIRED_ERROR).toString(),
        trigger: "blur",
      },
    ],
    password: [
      {
        required: true,
        message: this.$t(Messages.VALIDATION_REQUIRED_ERROR).toString(),
        trigger: "blur",
      },
    ],
  };

  created() {
    // Initialize company list on component creation
    this.getListCompany();
  }

  /**
   * Handle company search with debounce
   */
  private async getListCompany() {
    try {
      const response = await companyService.getLoginList();
      this.companies = response.map(item => ({
        ...item,
        key: item.id,
      }));
    } catch (error) {
      console.error("Failed to fetch companies:", error);
    }
  }

  /**
   * Handle form submission
   */
  private async handleSubmit() {
    try {
      const form = this.$refs.formRef as Form;
      await form.validate();

      this.isSubmitting = true;
      const payload = {
        username: `${this.formState.username}.${this.formState.company}`,
        password: this.formState.password,
        client_id: process.env.VUE_APP_CLIENT_ID,
        client_secret: process.env.VUE_APP_CLIENT_SECRET,
        grant_type: "password",
      };

      const response = await authService.signin(payload);
      await this.$store.dispatch("ACTIONS_POST_LOGIN", response);
      this.$message.success(this.$t("notif_login_successfull").toString());
      this.$emit("login-success", response);
    } catch (error: any) {
      if (error.isAxiosError) {
        this.$message.error(this.$t("notif_login_failed").toString());
      }
    } finally {
      this.isSubmitting = false;
    }
  }
}
