var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-form-model",
    {
      ref: "formRef",
      attrs: { model: _vm.formState, rules: _vm.rules, layout: "vertical" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.handleSubmit.apply(null, arguments)
        }
      }
    },
    [
      _c(
        "a-form-model-item",
        { attrs: { label: _vm.$t("lbl_company"), prop: "company" } },
        [
          _c(
            "a-select",
            {
              attrs: {
                placeholder: _vm.$t("common.select-text", {
                  text: _vm.$t("lbl_company")
                }),
                "data-testid": "cmb_company"
              },
              model: {
                value: _vm.formState.company,
                callback: function($$v) {
                  _vm.$set(_vm.formState, "company", $$v)
                },
                expression: "formState.company"
              }
            },
            _vm._l(_vm.companies, function(company) {
              return _c(
                "a-select-option",
                { key: company.id, attrs: { value: company.id } },
                [
                  _c(
                    "a-tooltip",
                    [
                      _c("template", { slot: "title" }, [
                        _vm._v(_vm._s(company.name))
                      ]),
                      _vm._v(" " + _vm._s(company.name) + " ")
                    ],
                    2
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      ),
      _c(
        "a-form-model-item",
        { attrs: { label: _vm.$t("lbl_username"), prop: "username" } },
        [
          _c(
            "a-input",
            {
              attrs: {
                placeholder: _vm.$t("common.insert-text", {
                  text: _vm.$t("lbl_username")
                }),
                autocomplete: "off",
                "data-testid": "txt_username"
              },
              model: {
                value: _vm.formState.username,
                callback: function($$v) {
                  _vm.$set(_vm.formState, "username", $$v)
                },
                expression: "formState.username"
              }
            },
            [
              _c("a-icon", {
                attrs: { slot: "prefix", type: "user" },
                slot: "prefix"
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-form-model-item",
        { attrs: { label: _vm.$t("lbl_password"), prop: "password" } },
        [
          _c(
            "a-input-password",
            {
              attrs: {
                placeholder: _vm.$t("common.insert-text", {
                  text: _vm.$t("lbl_password")
                }),
                autocomplete: "off",
                "data-testid": "txt_password"
              },
              model: {
                value: _vm.formState.password,
                callback: function($$v) {
                  _vm.$set(_vm.formState, "password", $$v)
                },
                expression: "formState.password"
              }
            },
            [
              _c("a-icon", {
                attrs: { slot: "prefix", type: "lock" },
                slot: "prefix"
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-form-model-item",
        [
          _c(
            "a-button",
            {
              attrs: {
                type: "primary",
                "html-type": "submit",
                loading: _vm.isSubmitting,
                block: "",
                "data-testid": "btnSubmit"
              }
            },
            [
              _vm._v(" " + _vm._s(_vm.$t("lbl_signin")) + " "),
              _c("a-icon", { attrs: { type: "login" } })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "text-center" },
        [
          _c(
            "router-link",
            {
              staticClass: "forgot-password-link",
              attrs: { to: "./forgot-password" }
            },
            [_vm._v(" " + _vm._s(_vm.$t("lbl_forgot_password")) + "? ")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "text-center" },
        [
          _c(
            "router-link",
            { staticClass: "forgot-password-link", attrs: { to: "/qr" } },
            [_vm._v(" " + _vm._s(_vm.$t("lbl_open_qr_code")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }