


























import SigninForm from "@/components/Auth/SigninForm.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    SigninForm,
  },
})
export default class LoginPage extends Vue {}
