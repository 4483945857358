




























import { useArAgingReport } from "@/hooks";
import { ReportAgingDashboardDto } from "@interface/ar-aging-report/v2";
import { Component, Vue } from "vue-property-decorator";
import DrawerInvoice from "./DrawerInvoice.vue";

@Component({
  components: {
    DrawerInvoice,
  },
})
export default class ArAgingReminder extends Vue {
  drawer = {
    visible: false,
    data: undefined as undefined | ReportAgingDashboardDto,
    toggle(): void {
      this.visible = !this.visible;
    },
  };
  loading = false;
  dataReport: ReportAgingDashboardDto[] = [];

  created(): void {
    this.fetchReport();
  }

  fetchReport(): void {
    const { findAllDashboard } = useArAgingReport();
    this.loading = true;
    findAllDashboard()
      .then(res => {
        const list: ReportAgingDashboardDto[] = [];
        for (const value of Object.values(res)) {
          list.push(value);
        }
        list.sort((left, right) => left.key - right.key);
        this.dataReport = list;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  toggleDrawer(val: ReportAgingDashboardDto): void {
    this.drawer.visible = true;
    this.drawer.data = val;
  }

  onDrawerClose(): void {
    this.drawer.data = undefined;
    this.drawer.visible = false;
  }

  getTitle(key: string): string {
    if (key === "outstanding1") return this.$t("lbl_1_to_15_days").toString();
    else if (key === "outstanding2")
      return this.$t("lbl_16_to_30_days").toString();
    else if (key === "outstanding3")
      return this.$t("lbl_31_to_60_days").toString();
    else if (key === "outstanding4")
      return this.$t("lbl_more_than_60").toString();
    else return "";
  }
}
