var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-page" },
    [
      _c("div", { staticClass: "pattern-overlay" }),
      _c(
        "a-row",
        {
          staticClass: "login-container",
          attrs: { type: "flex", align: "middle" }
        },
        [
          _c("a-col", { staticClass: "left-content", attrs: { span: 12 } }, [
            _c("div", { staticClass: "title-container" }, [
              _c("div", { staticClass: "title-wrapper" }, [
                _c("h1", { staticClass: "title" }, [
                  _vm._v("Welcome to "),
                  _c("span", { staticClass: "highlight" }, [_vm._v("SPP")])
                ]),
                _c("h2", { staticClass: "tagline" }, [
                  _vm._v("Enterprise Resource Planning")
                ])
              ]),
              _c("p", { staticClass: "subtitle" }, [
                _vm._v(
                  " Streamline your business operations with our comprehensive ERP solution. Experience seamless integration, real-time analytics, and enhanced productivity. "
                )
              ])
            ])
          ]),
          _c(
            "a-col",
            { staticClass: "right-content", attrs: { span: 12 } },
            [_c("a-card", [_c("SigninForm")], 1)],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }